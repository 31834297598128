:root {
  --color-primary: rgb(95, 176, 52);
  --color-primary-a50: #1b1b2183;
  --color-primary-light: #535357;

  --color-background-gray: rgb(249, 249, 249);
  --color-green-primary: rgb(95, 176, 52);
  --color-black: #000;
  --color-white: #fff;
  --color-black-subtext: rgb(73, 72, 72);
  --color-black-a20: rgba(0, 0, 0, 0.2);
  --color-gray: rgb(212, 212, 212);

  --color-white: #fff;
  --color-black: #000;

  --color-black-text: #452e4f;
  --color-black-back: #181d24;
  --color-black-ligth: #222831;
  --color-white-alpha: rgba(255, 255, 255, 0.6);

  --color-tranparent: transparent;

  --color-gray: #e5e5e5;
  --color-gray-dark: #76767d;
  --color-gray-light: #f2f3f5;

  --color-red: #ff3664;
  --color-red-dark: #af183b;

  --color-red-alpha: #ff366581;

  --color-green: rgb(22, 107, 29);
  --color-green-light: rgb(38, 172, 49);

  --color-blue: rgb(22, 23, 107);
  --color-blue-light: rgb(38, 101, 172);

  --color-gray-home: #a9a9a9;
  --color-gray-card: #f3f3f3;
  --color-gray-dark-circle: #a9a9a9;
  --color-gray-button: #dadada;

  --color-red-dark: #90111c;
  --color-red-back: #bd1728;
  --color-red-light: #fbdde1;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input,
select,
textarea {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  color: var(--color-black);
  resize: none;
}

input::placeholder {
  color: var(--color-gray-dark);
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  min-height: 27px;
  text-align: left;
}

.grecaptcha-badge {
  visibility: hidden;
}

ion-datetime {
  padding: 10px 0px;
}

.datetime-placeholder {
  color: var(--color-black-text) !important;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.my-modal {
  --background: rgba(34, 34, 34, 0.301);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type=date],
 input[type=time] {
     height: 24px;
 }
